<template>
  <div>

    <el-dialog
        v-if="currentRow"
        :title="'Aufgabe ' + currentRow.title + (currentRow.id ? ' bearbeiten' : ' hinzufügen')"
        v-model="showEditForm">
      <el-row class="mt-4">
        <el-col :span="24">

          <h6>Aufgabe</h6>
          <el-input
              placeholder="Bezeichnung"
              v-model="currentRow.title"></el-input>

          <h6 class="mt-4">Beschreibung</h6>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="Nähere Beschreibung"
            v-model="currentRow.description"></el-input>

          <h6 class="mt-4">Dauer (in Minuten)</h6>
          <el-input v-model="currentRow.duration">
            <template #append>Minuten</template>
          </el-input>

          <el-row>
            <el-col :span="12">
              <h6 class="mt-4">Wiederholung (in Tagen)</h6>
              <el-input v-model="currentRow.repeat_days">
                <template #append>Tage</template>
              </el-input>
            </el-col>
            <el-col :span="11" :offset="1">
              <h6 class="mt-4">Anzahl Zyklen</h6>
              <el-input v-model="currentRow.number_of_cycles" class="text-right"></el-input>
            </el-col>
          </el-row>

          <h6 class="mt-4">Allgemeine Aufgabe</h6>
          <el-checkbox v-model="currentRow.is_general">Diese Aufgabe ist allgemeingültig bzw. nicht studienspezifisch</el-checkbox>

          <div v-if="!currentRow.is_general">
            <h6 class="mt-4">Studien</h6>
            <el-row class="mt-2">
              <el-col :span="24">
                <survey-table :data="currentRow.surveys" :showfilter="false" :deleteable="true" :displaypivot="true" @on-row-delete="onDeleteSurveyFromTask"></survey-table>

                <div class="mt-4">Studien zuweisen:</div>

                <el-select placeholder="Studie auswählen" size="medium" v-model="selectedSurvey" filterable>
                  <el-option
                    v-for="item in surveys"
                    :key="item.id"
                    :label="item.title_short"
                    :value="item">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </div>

        </el-col>
      </el-row>

      <template #footer>
        <el-row>
          <el-col :span="10" class="text-left">
            <el-popconfirm
                v-if="currentRow.id"
                title="Sie möchten diese Aufgabe löschen?"
                confirmButtonText='Ja, löschen'
                confirmButtonType="danger"
                cancelButtonText='Nein'
                iconColor="red"
                @confirm="onRowDeleteConfirmed(currentRow)">
              <template #reference>
                <el-button type="danger">Löschen</el-button>
              </template>
            </el-popconfirm>
          </el-col>
          <el-col :span="14" class="dialog-footer text-right">
            <el-button @click="showEditForm = false">Abbrechen</el-button>
            <el-button type="primary" @click="saveRow()">{{(currentRow.id ? ' Speichern' : 'Hinzufügen')}}</el-button>
          </el-col>
        </el-row>

      </template>
    </el-dialog>

    <el-button type="primary" @click="onBtnNewClick" size="mini" icon="el-icon-circle-plus">Aufgabe hinzufügen</el-button>

    <el-table
        ref="surveyTaskTable"
        :data="tableData"
        :default-sort = "{prop: 'title', order: 'ascending'}"
        style="width: 100%"
        highlight-current-row
        @row-click="onRowEditClick"
        stripe>
      <el-table-column
          prop="title"
          label="Titel"
          sortable>
      </el-table-column>
      <el-table-column
          width="150">
        <template #default="scope">
          <div class="text-right">
            <el-button size="mini" type="warning" @click="onRowEditClick(scope.row)" icon="el-icon-edit"></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          width="70">
        <template #default="scope">
          <div class="text-right">
            <el-popconfirm
                :title="'Sie möchten diese Aufgabe löschen?'"
                confirmButtonText='Ja, löschen'
                confirmButtonType="danger"
                cancelButtonText='Nein'
                iconColor="red"
                @confirm="onRowDeleteConfirmed(scope.row)">
              <template #reference>
                <el-button size="mini" type="warning" icon="el-icon-delete"></el-button>
              </template>
            </el-popconfirm>

          </div>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SurveyTable from "../../parts/SurveyTable";
const _ = require('lodash');

export default {
  name: "SettingTask",
  props: [],
  components: {SurveyTable},
  data() {
    return {
      tableData: [],
      currentRow: null,
      showEditForm: false,
      selectedSurvey: null
    }
  },
  mounted: async function () {
    await this.getSurveyTasks()
  },
  computed: {
    ...mapGetters('surveys', ['surveys', 'surveyTasks']),
  },
  watch: {
    surveyTasks: function (val) {
      this.tableData = _.cloneDeep(val)
    },
    'selectedSurvey': function(survey) {

      if(survey) {
        const exist = _.find(this.currentRow.surveys, (o) => o.id ==survey.id)
        if(exist) return

        let allSurveys = _.cloneDeep(this.currentRow.surveys)
        allSurveys.push(survey)

        this.currentRow.surveys = allSurveys
        this.selectedSurvey = null
      }

    },
  },
  methods: {
    ...mapActions('surveys', ['getSurveys', 'getSurveyTasks', 'deleteSurveyTask', 'saveSurveyTask']),
    async onRowDeleteConfirmed(row) {
      await this.deleteSurveyTask(row)
      this.showEditForm = false
      await this.getSurveyTasks()
    },
    async onBtnNewClick() {
      this.currentRow = {
        id: null,
        title: '',
        description: '',
        duration: '',
        surveys: [],
        is_general: false
      }
      this.showEditForm = true
      await this.getSurveys()

    },
    async onRowEditClick(row) {
      this.currentRow = row
      this.showEditForm = true
      await this.getSurveys()
    },
    async saveRow() {
      await this.saveSurveyTask(this.currentRow)
      this.showEditForm = false
      await this.getSurveyTasks()
    },
    onDeleteSurveyFromTask(survey){
      this.currentRow.surveys = _.filter(this.currentRow.surveys, (o) => o.id !== survey.id)
    },
  }
}
</script>
<style scoped>
</style>
