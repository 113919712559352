<template>
  <div>

    <h2>Einstellungen</h2>
    <el-tabs type="card" tab-position="left">
    <el-tab-pane label="Studien">
      <setting-survey></setting-survey>
    </el-tab-pane>
    <el-tab-pane label="Aufgaben">
      <setting-task></setting-task>
    </el-tab-pane>
      <el-tab-pane label="CRO">
        <setting-c-r-o></setting-c-r-o>
      </el-tab-pane>
      <el-tab-pane label="Tätigkeiten">
        <setting-activity></setting-activity>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import SettingTask from "@/components/study/setting/SettingTask";
import SettingSurvey from "@/components/study/setting/SettingSurvey";
import SettingCRO from "./setting/SettingCRO";
import SettingActivity from "./setting/SettingActivity.vue";

export default {
  name: "Setting",
  props: [],
  components: {
    SettingActivity,
    SettingCRO,
    SettingTask,
    SettingSurvey
  },
  data () {
    return {
    }
  },
  mounted: function () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
}

}
</script>
<style scoped>
</style>
