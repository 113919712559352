<template>
  <div>
    <survey-form-modal :survey="newSurvey" v-model="showSurveyForm" :editable="true"></survey-form-modal>

    <el-button type="primary" @click="onBtnNewClick" size="mini" icon="el-icon-circle-plus">Studie hinzufügen</el-button>
    <survey-table :data="tableData" :showfilter="true" :clickable="true" :deleteable="true" :expandable="false" :editable="true"></survey-table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SurveyTable from "../../parts/SurveyTable";
import SurveyFormModal from "../../survey/SurveyFormModal";

const _ = require('lodash');

export default {
  name: "SettingSurvey",
  props: [],
  components: {SurveyTable, SurveyFormModal},
  data() {
    return {
      tableData: [],
      currentRow: null,
      showEditForm: false,
      selectedSurvey: null,
      newSurvey: {}
    }
  },
  mounted: async function () {
    await this.getSurveys()
  },
  computed: {
    ...mapGetters('surveys', ['surveys', 'surveyTasks']),
  },
  watch: {
    surveys: function (val) {
      this.tableData = _.cloneDeep(val)
    },
    'selectedSurvey': function(survey) {

      if(survey) {
        const exist = _.find(this.currentRow.surveys, (o) => o.id ==survey.id)
        if(exist) return

        let allSurveys = _.cloneDeep(this.currentRow.surveys)
        allSurveys.push(survey)

        this.currentRow.surveys = allSurveys
        this.selectedSurvey = null
      }

    },
  },
  methods: {
    ...mapActions('surveys', ['getSurveys', 'getSurveyTasks', 'deleteSurveyTask', 'saveSurveyTask']),
    async onRowDeleteConfirmed(row) {
      await this.deleteSurvey(row)
      this.showEditForm = false
      await this.getSurveys()
    },
    async onBtnNewClick() {
      this.newSurvey = {
        id: null,
        title: '',
        title_short: ''
      }
      this.showSurveyForm = true
    },
    async onRowEditClick(row) {
      this.currentRow = row
      this.showEditForm = true
      await this.getSurveys()
    },
    async saveRow() {
      await this.saveSurvey(this.currentRow)
      this.showEditForm = false
      await this.getSurveys()
    },
    onDeleteTaskFromSurvey(task){
      this.currentRow.tasks = _.filter(this.currentRow.tasks, (o) => o.id !== task.id)
    },
  }
}
</script>
<style scoped>
</style>
